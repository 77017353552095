<template>
  <div>
    <div class="fb-style paidmedia-background firstSectionPadFix">
      <div class="fb-grid">
        <div class="fb-content">
          <h1>Tailormade applications & systems</h1>
          <h3>We create apps and systems for iOS, android and web.</h3>
          <router-link to="/contact"
            ><a
              ><button class="fb-content-btn">Start building</button></a
            ></router-link
          >
        </div>
        <img src="@/assets/images/icon-simple-minutemailer.svg" />
      </div>
    </div>

    <div class="label labelColorGrey">
      <div class="hrdiv hrdiv-grey-color"></div>
      <div class="pdiv">
        <p>&nbsp;Tailormade applications & systems</p>
      </div>
    </div>

    <div class="SecondBannerWrapper labelColorGrey">
      <div class="gridSBW">
        <div class="FirstWrap">
          <div class="wrap">
            <h2>Flutter</h2>
            <p>
              We use Flutter to develop our customers cross platform mobile
              applications. Flutter is a software developer framework from
              Google and ensures a fast, responsive and reliable application.
              With flutter we can create great products that live up to today's
              standards but also ensure that your application will be future
              proof.
            </p>
          </div>
          <div class="wrap">
            <h2>Firebase</h2>
            <p>
              Firebase works directly with flutter and makes the applications
              very fast and secure. We use Firebase as our Realtime database,
              where your applications data can be synchronized across iOS,
              Android and web devices. Firebase is created by Google and lives
              up to the standards of a state of art cloud provider.
            </p>
          </div>
          <div class="wrap">
            <h2>Angular</h2>
            <p>
              Angular is a JavaScript based web framework developed by Google
              which enables us to create responsive web apps that offer full
              customizable functionality based on the requirements of the
              client, while retaining the performance of static web pages.
            </p>
          </div>
          <div class="wrap">
            <h2>Integrations</h2>
            <p>
                We can make most integrations with our software engineer team,
                currently we have worked with full integrations such as payment
                gateways, accounting programs, delivery partners, CMS systems.
                If the 3rd party has an exposed API provided for developers, we
                can write an integrator for it.
            </p>
          </div>
          <div class="wrap wrap-btn">
            <router-link to="/contact"
              ><button class="blk btn">
                <a>Build your system</a>
              </button></router-link
            >
          </div>
        </div>

        <div class="SecondWrap">
          <h2>Platforms we support</h2>
          <div class="platforms">
            <div class="platformItem">
              <ul>
                <li>Flutter</li>
                <li>VueJS</li>
                <li>NestJS</li>
                <li>Angular</li>
                <li>Firebase Integration</li>
                <li>e-Conomic Integrations</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainWhatWeDo />
    <Testimonials />
    <WhoWeAre />
    <div class="trustedby">
      <Trustedby />
    </div>
  </div>
</template>

<script>
import Testimonials from "../components/Testimonials.vue";
import MainWhatWeDo from "../components/MainWhatWeDo.vue";
import WhoWeAre from "./WhoWeAre.vue";
import Trustedby from "../components/Trustedby.vue";
export default {
  name: "Paid",
  title: "Tailormade Applications and Systems",
  components: { Testimonials, MainWhatWeDo, WhoWeAre, Trustedby },
};
</script>

<style scoped>
@import "../assets/css/web.css";
@import "../assets/css/tablet.css";
@import "../assets/css/mobile.css";
.trustedby {
  background: #f8f8f8;
}
</style>
